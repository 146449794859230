import {
  useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
  useCallback,
} from 'react';
import { baseUrl } from '../helpers/urls';

const useCards = () => {

  const {
    getAccessTokenSilently,
  } = useAuth0();

  const getById = useCallback(async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}/cards/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }, [getAccessTokenSilently]);

  const getAmountOfCard = useCallback(async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}/cards/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.amount;
  }, [getAccessTokenSilently]);

  const createTransaction = useCallback(async ({amount, cardnr, products}) => {
    console.log(products);
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}/cards/${cardnr}/transactions`, 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {amount, products}
    });
 
    return response.data;
  }, [getAccessTokenSilently]);

  const createCard = useCallback(async ({compId, walletId, cardnr}) => {
    const token = await getAccessTokenSilently();

    console.log(cardnr);
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}/companies/${compId}/wallets/${walletId}/cards`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {cardnr}
    });

    return response.data;
  }, [getAccessTokenSilently]);

  const getTransactionsOfCard = useCallback(async ({cardnr}) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}/${cardnr}/transactions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }, [getAccessTokenSilently]);

  const updateCard = useCallback(async (cardNr, {isBlocked, isGold}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'PUT',
      url: `${baseUrl}/cards/${cardNr}/`,
      data: {isBlocked, isGold},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }, [getAccessTokenSilently]);

  return {
    getById,
    getAmountOfCard,
    createTransaction,
    createCard,
    getTransactionsOfCard,
    updateCard
  };
};

export default useCards;