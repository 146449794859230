import {
  useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
  useCallback,
} from 'react';

import { baseUrl } from '../helpers/urls';

const useUsers = () => {

  const {
    getAccessTokenSilently,
  } = useAuth0();

  const getAll = useCallback(async (searchTerm) => {
    console.log(`${baseUrl}/users?searchTerm=${searchTerm}`);
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/users?searchTerm=${searchTerm}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {searchTerm: searchTerm}
    });
 
    return response.data;
  }, [getAccessTokenSilently]);

  const getById = useCallback(async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${baseUrl}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data)
    return response.data;
  }, [getAccessTokenSilently]);

  const create = useCallback(async () => {
    const token = await getAccessTokenSilently();

    console.log(token);
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}/users`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }, [getAccessTokenSilently]);

  const update = useCallback(async (id, {firstname, lastname, email, street, number, postalcode, city, country}) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'PUT',
      url: `${baseUrl}/users/${id}`,
      data: {firstname, lastname, email, street, number, postalcode, city, country},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }, [getAccessTokenSilently]);

  const login = useCallback(async () => {
    const token = await getAccessTokenSilently();
    console.log(token)
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/users/login`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }, [getAccessTokenSilently]);

  const deleteUser = useCallback(async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios({
      method: 'DELETE',
      url: `${baseUrl}/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  });

  return {
    getAll,
    getById,
    create,
    login,
    update,
    deleteUser
  };

};

export default useUsers;